@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

Select {
  font-family: inherit; /* 폰트 상속 */
  // background: url(../img/btn-drop-arrow.svg) no-repeat 98% 50%;
  /* 네이티브 화살표를 커스텀 화살표로 대체 */
  -webkit-appearance: none;
  /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
}

Select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear {
  display: none;
}

/* input type number 에서 화살표 제거 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*# sourceMappingURL=reset.css.map */

// "Montserrat",

@mixin eng() {
  font-family: "Montserrat";
}

html {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    HelveticaNeue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #000;
}

body {
  min-width: 360px;
}

.hashLink {
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}

.slick-slide img {
  width: 100%;
}

.conTit {
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
}

.desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  a {
    color: inherit;
  }
  b {
    font-weight: bold;
    &.red {
      color: #ff7d72;
    }
    &.blue {
      color: #558eff;
    }
  }
}

.flexColumn4 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.flexColumn8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flexColumn20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexColumn36 {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.flexColumn80 {
  display: flex;
  flex-direction: column;
  gap: 80px;
  .innerFlex {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.displayNone {
  display: none !important;
}
.gap12 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}
.blueLink {
  min-width: 223px;
  text-align: center;
  padding: 8px 12px;
  border-radius: 100px;
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: #81c0ff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

$s1: 8px;
$s2: 16px;

@mixin imgCap() {
  .imgWrap {
    text-align: right;
    img {
      display: block;
      width: 100%;
    }
    .caption {
      font-size: 12px;
      color: #707070;
      margin-top: 4px;
    }
    @content;
  }
}
@include imgCap;

@mixin DetailInfo() {
  .detailInfo {
    padding-top: 12px;
    border-top: 1px solid #707070;
    table {
      table-layout: fixed;
      width: 100%;
      word-break: break-all;
    }
    th,
    td {
      padding-top: 12px;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;
    }
    th {
      font-family: "Pretendard";
      width: 60px;
      font-weight: bold;
    }
    td {
      padding-left: 12px;
    }
    a {
      font-family: "Pretendard";
      color: inherit;
    }
    @content;
  }
}
@include DetailInfo;

@mixin orderOnline() {
  .orderOnline {
    text-align: center;
    margin-top: 40px;
    .orderBtn {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      line-height: 35px;
      padding: 0 12px;
      border-radius: 50px;
      border: 1px solid #000;
      box-sizing: border-box;
      color: #000;
      background-color: #d9c8c2;
    }
    @content;
  }
}

@mixin lineClamp($count: 2) {
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

@mixin keyword($fonstSize: 14) {
  .keyword {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    // max-width: 340px;
    .item {
      @include eng;
      font-size: $fonstSize + px;
      font-weight: 600;
      line-height: 19px;
      padding: 0 8px;
      border-radius: 50px;
      border: 1px solid #000;
      box-sizing: border-box;
      &.NEWS {
        background-color: #ffdb43;
      }
      &.BRAND {
        background-color: #29ddb9;
      }
      &.TRAVEL {
        background-color: #9099ff;
      }
      &.ACTIVITY {
        background-color: #ff7efc;
      }
      &.HISTORY {
        background-color: #6abaff;
      }
      &.HOW-TO {
        background-color: #b4fa6b;
      }
      &.PEOPLE {
        background-color: #ff8096;
      }
      &.SPACE {
        background-color: #a7e8f4;
      }
    }
    @content;
  }
}
@mixin inner {
  .inner {
    box-sizing: border-box;
    width: 100%;
    max-width: 616px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
    &.innerFlex {
      display: flex;
      flex-direction: column;
      gap: 28px;
      margin-top: 80px;
      margin-bottom: 110px;
    }
    @content;
  }
}
@include inner;
// .innerFlex {
//   .slick-slider {
//     margin-bottom: 26px;
//   }
// }
.innerFull {
  max-width: 100%;
}
.inner380 {
  @include inner {
    max-width: 410px;
  }

  .article1 {
    h2 {
      font-size: 30px;
    }
    .subTit {
      margin-bottom: 0;
    }
    .keyImg {
      margin: 0;
      img {
        display: block;
      }
    }
  }

  .fullImg {
    display: block;
    width: 100%;
    margin: 0;
  }
}
.inner1220 {
  max-width: 1220px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}

@mixin location() {
  .location {
    position: relative;
    padding-left: 20px;
    line-height: 20px;
    margin-bottom: 28px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      background: url(../public/img/ic_location.svg) center no-repeat;
    }
  }
  @content;
}

// Header.js
.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 24px;
  font-weight: bold;
  background-color: #b1d6db;
  background-color: #ffcf4b;
  border-bottom: 1px solid #000;
  .hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url(../public/img/hamburger.svg) center no-repeat;
  }
  .inner {
    max-width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 30px;
    transition: 0.3s;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 56px;
    transition: 0.3s;
  }
  .tit {
    @include eng;
    font-weight: 800;
  }
  @media screen and (max-width: 768px) {
    .inner {
      padding: 0 20px;
    }
    .left {
      gap: 30px;
    }
  }
}
.offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
  &.ac {
    display: block;
  }
  .sideMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 364px;
    height: 100%;
    background-color: #fff;
    border: 1px solid #707070;
    .close {
      text-indent: -9999px;
      overflow: hidden;
      width: 20px;
      height: 20px;
      padding: 10px;
      background: url(../public/img/ic_sideClose.svg) center no-repeat;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .tit {
      @include eng;
      padding-top: 120px;
      padding-left: 30px;
      font-size: 36px;
      font-weight: 900;
      padding-bottom: 24px;
    }
    .menuList {
      display: flex;
      flex-direction: column;
      width: 100%;
      .menu {
        transition: 0.3s;
        cursor: pointer;
        padding: 28px;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;
        box-sizing: border-box;
        border: 1px solid #707070;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        &:last-child {
          border-bottom: 1px solid #707070;
        }
        &.ac,
        &:hover {
          background-color: #ffdf74;
        }
        h4 {
          font-size: 20px;
          font-weight: bold;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dim {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    &.ac {
      display: block;
    }
  }
}

.d-pc {
  display: block !important;
}
.d-mobile {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .d-pc {
    display: none !important;
  }
  .d-mobile {
    display: block !important;
  }
}

.search {
  position: relative;
  input {
    font-size: 14px;
    width: 283px;
    height: 36px;
    border: 1px solid #707070;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 100px;
    outline: 0;
    ::placeholder {
      color: #969696;
    }
  }
  .btn {
    padding: 0;
    width: 20px;
    height: 20px;
    background: url(../public/img/ic_search.svg) center no-repeat;
    border: 0;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    text-indent: -9999px;
    overflow: hidden;
    transition: 0.3s;
  }
  &.d-mobile {
    .btn {
      right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    &.ac {
      display: block !important;
      position: fixed;
      top: 96px;
      left: 16px;
      right: 16px;
      input {
        width: 100%;
        height: 56px;
      }
      .btn {
        right: 20px;
      }
    }
  }
}

// Article1.js
.article1 {
  transition: 0.3s;
  margin-top: 63px;
  margin-bottom: 80px;
  @include keyword {
    margin-bottom: 24px;
  }
  // .tagWrap {
  //   display: flex;
  //   gap: 12px;
  //   margin-bottom: 24px;
  //   .tag {
  //     font-size: 14px;
  //     font-weight: 500;
  //     line-height: 27px;
  //     padding: 0 12px;
  //     display: inline-flex;
  //     border: 1px solid #000;
  //     border-radius: 50px;
  //     &.TRAVEL {background-color: #9099FF;}
  //     &.ACTIVITY {background-color: #FF7EFC;}
  //     &.HISTORY {background-color: #6ABAFF;}
  //     &.BRAND {background-color: #29DDB9;}
  //     &.HOW-TO {background-color: #B4FA6B;}
  //     &.PEOPLE {background-color: #FF8096;}
  //     &.SPACE {background-color: #A7E8F4;}
  //   }
  // }
  h2 {
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 8px;
  }
  .subTit {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 48px;
  }
  .keyImg {
    img {
      width: 100%;
    }
    margin: 48px 0;
  }
  .infoBx {
    padding: 20px;
    border: 1px solid #000;
    background-color: #f2f2f2;
    box-sizing: border-box;
    .tit {
      font-size: 18px;
      font-weight: bold;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 24px;
      .item {
        position: relative;
        padding-left: 16px;
        line-height: 1.5;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
          width: 4px;
          height: 4px;
          border-radius: 10px;
          background-color: #000;
        }
        h3 {
          font-size: 16px;
          font-weight: 600;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 36px;
    margin-bottom: 45px;
    h2 {
      margin-bottom: 4px;
    }

    // .subTit {
    //   margin-bottom: 0;
    // }
  }
}

.article1 + .article2 {
  margin-top: 80px;
}

// Article2.js
.article2 {
  margin-top: 110px;

  .titWrap {
    padding-bottom: 28px;
    margin-bottom: 28px;
  }

  &.orange .titWrap {
    border-bottom: 1px solid #faad33;
  }
  &.blue .titWrap {
    border-bottom: 1px solid #81c0ff;
  }
  &.green .titWrap {
    border-bottom: 1px solid #5ae093;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }
  &.orange {
    h3 {
      color: #ff7600;
    }
  }
  &.blue {
    h3 {
      color: #3198ff;
    }
  }
  &.green {
    h3 {
      color: #0dbc58;
    }
  }
}

// Contents.js
@mixin contents {
  .contents {
    & + .contents {
      margin-top: 80px;
    }
    @include imgCap;
    .imgWrap + .imgWrap {
      margin-top: 16px;
    }

    .itemWrap {
      display: flex;
      gap: 12px;
      margin-top: 32px;
      margin-bottom: 8px;
      .tag {
        display: none;
        font-size: 14px;
        color: #707070;
        &.dog,
        &.kiz,
        &.obs {
          display: inline-flex;
        }
        align-items: center;
        height: 28px;
        border-radius: 40px;
        box-sizing: border-box;
        border: 1px solid #707070;
        padding: 0 12px;
        &.dog::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../public/img/ic_dog.svg) center no-repeat;
        }
        &.kiz::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../public/img/ic_kiz.svg) center no-repeat;
        }
        &.obs::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../public/img/ic_obs.svg) center no-repeat;
        }
      }
    }

    .conTit {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    @include location;

    .desc {
      font-size: 18px;
      font-weight: 400;
      line-height: 2;
      margin-bottom: 28px;
    }

    .checkList {
      padding-top: 24px;
      border-top: 1px solid #707070;
      .tit {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        padding-left: 20px;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          background: url(../public/img/ic_checkList.svg) center no-repeat;
          margin-bottom: 16px;
        }
      }
      .listWrap {
        padding-left: 20px;
        padding-top: 16px;
      }
      .list {
        position: relative;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        padding-left: 20px;
        color: #000;
        a {
          color: inherit;
        }
        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          background: url(../public/img/ic_check.svg) center no-repeat;
        }
      }
      .list + .list {
        margin-top: 12px;
      }
    }

    .stayLink {
      text-align: center;
      margin-top: 40px;
      .stayBtn {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 35px;
        padding: 0 12px;
        border-radius: 50px;
        border: 1px solid #000;
        box-sizing: border-box;
        color: #000;
      }
      &.orange .stayBtn {
        background-color: #faad33;
      }
      &.blue .stayBtn {
        background-color: #81c0ff;
      }
      &.green .stayBtn {
        background-color: #5ae093;
      }
    }
    @include DetailInfo {
      margin-top: 28px;
    }
    @content;
  }
}
@include contents {
  &.contents2 {
    .cate {
      font-size: 20px;
      font-weight: bold;
      font-family: "Pretendard";
      margin-bottom: 8px;
      &.YOGA {
        color: #6abaff;
      }
      &.FOOD {
        color: #ff9055;
      }
      &.TEA {
        color: #29ddb9;
      }
      &.DRAWING {
        color: #ff8096;
      }
      &.CRAFT {
        color: #9099ff;
      }
    }
    .conTit {
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 8px;
    }
    .itemWrap {
      margin: 0;
      margin-bottom: 28px;
    }
    .desc {
      margin-top: 28px;
    }
    .detailInfo {
    }
  }
}

@include contents {
  &.contents3 {
    margin-bottom: 80px;
    em {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      color: #6abaff;
      margin-bottom: 8px;
    }
    .imgWrap {
      margin-bottom: 28px;
    }
    .conTit {
      font-size: 24px;
    }
  }
}

@include contents {
  &.contents4 {
    margin-bottom: 80px;
    .inner {
      .detailInfo,
      .desc,
      .reactSlick,
      .conTit {
        margin: 0;
      }
    }
  }
}

@include contents {
  &.contents5 {
    margin-bottom: 110px;
    @include orderOnline() {
      .orderBtn {
        background-color: #ffdb43;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
    em {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      color: #6abaff;
      margin-bottom: 8px;
    }
    b {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 12px;
      display: inline-block;
    }
    .conTit {
      font-size: 24px;
    }
    .detailInfo {
      padding: 0;
    }
    .detailInfo th {
      width: 70px;
    }
    .detailInfo th,
    .detailInfo td {
      padding-top: 28px;
    }
    .detailInfo tr:first-child td,
    .detailInfo tr:first-child th {
      padding-top: 24px;
    }
    .imgWrap {
      text-align: left;
    }
    .imgList {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
    @include imgCap;
    .video {
      iframe {
        width: 100%;
      }
    }
    .imgWrap,
    .orderOnline,
    .detailInfo,
    .desc,
    .reactSlick,
    .conTit {
      margin: 0;
    }
  }
}

.recommend {
  margin-top: 80px;
  margin-bottom: 230px;
  .tit {
    padding-top: 32px;
    border-top: 1px solid #707070;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 28px;
  }
  .cardWrap {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .card {
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    background-color: #eaeaea;
    box-sizing: border-box;
    min-height: 208px;
    .imgWrap {
      width: 100%;
      aspect-ratio: 283/182;
      border-radius: 12px;
      overflow: hidden;
      img {
        display: block;
        aspect-ratio: 285/210;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .rightCon {
      flex: 1 0 0;
      // padding: 20px;
      padding-top: 12px;
      padding-bottom: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
    }
    @include keyword {
      .item {
        font-weight: 500;
        line-height: 20px;
      }
    }
    .conTit {
      font-size: 18px;
      line-height: 1.5;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .desc {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

// recommend 백업
// .recommend {
//   margin-top: 80px;
//   margin-bottom: 230px;
//   .tit {
//     padding-top: 32px;
//     border-top: 1px solid #707070;
//     width: 100%;
//     font-size: 20px;
//     font-weight: bold;
//     line-height: 1.4;
//     margin-bottom: 28px;
//   }
//   .cardWrap {
//     display: flex;
//     gap: 16px;
//   }
//   .card {
//     display: flex;
//     flex-direction: column;
//     flex: 1 0 0;
//     border: 1px solid #000;
//     box-sizing: border-box;
//     min-height: 208px;
//     .imgWrap {
//       width: 100%;
//       aspect-ratio: 285/210;
//       img {
//         display: block;
//         aspect-ratio: 285/210;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//     }
//     .rightCon {
//       flex: 1 0 0;
//       padding: 20px;
//       box-sizing: border-box;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       gap: 30px;
//     }
//     @include keyword;
//     .conTit {
//       font-size: 18px;
//       font-weight: bold;
//       margin-bottom: 12px;
//     }
//     .desc {
//       font-size: 16px;
//       line-height: 1.5;
//     }
//   }
// }

.article3 {
  margin-top: 80px;
  .em {
    font-size: 18px;
    font-weight: bold;
    color: #151515;
    margin-bottom: 8px;
  }
  .tit {
    font-size: 24px;
    margin-bottom: 28px;
    font-weight: bold;
  }
  .imgWrap {
    img {
      width: 100%;
    }
    .caption {
      font-size: 12px;
      color: #707070;
      margin-top: 4px;
    }
  }
  p {
    margin-top: 28px;
    font-size: 18px;
    line-height: 2;
    margin-bottom: 28px;
  }
  @include location() {
    .location {
      line-height: 1.5;
    }
  }
  @include orderOnline();
}

// ListGroup.js
.listGroupWrap {
  margin-bottom: 80px;
  margin-top: -80px;
  margin-top: -42px;
  padding-top: 28px;
  .keyImg {
    margin-top: 80px;
    margin-bottom: 28px;
    img {
      width: 100%;
    }
  }
  .badgeLink {
    margin: 24px 0;
    a {
      padding: 8px 12px;
      border: 1px solid #000;
      border-radius: 30px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: #3198ff;
      text-decoration: none;
    }
  }
}
.listGroup {
  font-size: 18px;
  font-family: "Pretendard";
  line-height: 1.5;
  & + .listGroup {
    margin-top: 24px;
  }

  .listTit {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    .num {
      font-family: "Pretendard";
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #3198ff;
      border-radius: 20px;
      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }
    .num + .num {
      margin-left: -4px;
    }
    .tit {
      font-weight: bold;
    }
  }

  .listDesc {
    .num {
      font-family: "Pretendard";
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #3198ff;
      border-radius: 20px;
      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }
  }

  .sub {
    font-size: 16px;
    line-height: 1.5;
    color: #707070;
    // margin-top: 8px;
  }
}

// ImgSlider.js
.reactSlick {
  padding-bottom: 23px;
  margin-bottom: 80px;
  img {
    width: 100%;
  }
  .slick-slide {
    div {
      outline: 0;
    }
  }
  .slick-dots {
    li {
      margin: 0;
    }
    li button {
      width: 16px;
      height: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      text-indent: -9999px;
      overflow: hidden;
    }
    li button::before {
      position: static;
      opacity: 1;
      padding: 0;
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid #707070;
      box-sizing: border-box;
    }
    li.slick-active button::before {
      border: 0;
      background-color: #3198ff;
    }
  }
}

.postImg {
  margin-bottom: 80px;
  img {
    width: 100%;
  }
}

.contents3 {
  margin-bottom: 28px;
  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 28px;
    &.blue {
      color: #3198ff;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 12px;
  }
  p {
    font-size: 18px;
    line-height: 2;
  }
  .insta {
    font-family: "Pretendard";
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #707070;
    padding-left: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    background: url(../public/img/ic_insta.svg) left center/32px no-repeat;
  }
}

.cardView {
  $gapY: 40px;
  $gapX: 20px;
  margin-bottom: 60px;
  .inner {
    display: flex;
    flex-wrap: wrap;
    gap: $gapY $gapX;
    max-width: 1220px;
  }
  .card {
    width: calc((100% - $gapX * 2) / 3);
    padding: 10px 10px 20px 10px;
    border-radius: 16px;
    background-color: #eaeaea;
    box-sizing: border-box;
    flex: 0 0 auto;
    transition: transform 0.3s ease-out 0s;
    &:hover {
      transform: translateY(-16px);
    }
    .imgWrap {
      aspect-ratio: 364/273;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      margin-bottom: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .badgeWrap {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        .badge {
          width: 28px;
          height: 28px;
          &.kiz {
            background: url(../public/img/ic_badge_kiz.png) center no-repeat;
          }
        }
      }
    }
    .keywordWrap {
      min-height: 52px;
    }
    @include keyword();
    h3 {
      font-family: "Pretendard";
      margin-top: 16px;
      min-height: 60px;
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 4px;
      @include lineClamp;
    }
    p {
      font-family: "Pretendard";
      font-size: 16px;
      line-height: 1.5;
      @include lineClamp;
    }
    &.card2 {
      width: calc((100% - $gapX * 2) / 2);
      h3 {
        min-height: initial;
      }
    }
  }
  @media screen and (max-width: 768px) {
    $gapY: 20px;
    .inner {
      row-gap: $gapY;
      .card {
        width: 100%;
        &:hover {
          transform: none;
        }
      }
    }
  }
}

.tab {
  [class*="inner"] {
    overflow: auto;
  }
  @include keyword(18) {
    min-height: 31px;
    flex-wrap: nowrap;

    .item.NEWS,
    .item.BRAND,
    .item.TRAVEL,
    .item.ACTIVITY,
    .item.HISTORY,
    .item.HOW-TO,
    .item.PEOPLE,
    .item.SPACE,
    .item.MAP {
      background-color: transparent;
    }
    .item.NEWS:hover,
    .ac.NEWS {
      background-color: #ffdb43;
    }
    .item.BRAND:hover,
    .ac.BRAND {
      background-color: #29ddb9;
    }
    .item.TRAVEL:hover,
    .ac.TRAVEL {
      background-color: #9099ff;
    }
    .item.ACTIVITY:hover,
    .ac.ACTIVITY {
      background-color: #ff7efc;
    }
    .item.HISTORY:hover,
    .ac.HISTORY {
      background-color: #6abaff;
    }
    .item.HOW-TO:hover,
    .ac.HOW-TO {
      background-color: #b4fa6b;
    }
    .item.PEOPLE:hover,
    .ac.PEOPLE {
      background-color: #ff8096;
    }
    .item.SPACE:hover,
    .ac.SPACE {
      background-color: #a7e8f4;
    }
    .item.MAP:hover,
    .ac.SPACE {
      background-color: #ff9055;
    }

    .item {
      white-space: nowrap;
      height: 30px;
      line-height: 30px;
      padding: 0 12px;
      cursor: pointer;
      box-sizing: border-box;
    }
  }
  margin-top: 83px;
  margin-bottom: 20px;
  transition: 0.3s;
  @media screen and (max-width: 768px) {
    margin-top: 36px;
  }
}

.cateTit {
  h2 {
    font-family: "Pretendard";
    font-size: 48px;
    line-height: 58px;
    font-weight: bold;
    margin-bottom: 4px;
    transition: 0.3s;
  }
  h3 {
    font-family: "Pretendard";
    font-size: 28px;
    line-height: 37px;
    margin-bottom: 48px;
    transition: 0.3s;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 32px;
      line-height: 38px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 45px;
    }
  }
}

body:has(.mainBg) {
  background: url(../public/img/bg_main.jpg) center;
}

.mainBg {
}
.visual {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 1200px) {
  }
}
.keyVisualWrap {
  position: relative;
  // overflow: hidden;
  width: 1920px;
  flex: 0 0 auto;
  @media screen and (max-width: 1200px) {
    flex: 1 0 0;
  }
}
.keyVisual {
  text-align: center;
  padding: 0 15px;
  padding-top: 124px;
  padding-bottom: 48px;
  transition: 0.3s;
  .imgWrap {
    margin: 0 auto;
    // margin-top: 124px;
    // margin-bottom: 55px;
    max-width: 665px;
    width: 100%;
    aspect-ratio: 665/300;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .mainObj {
    position: absolute;
    transform: scale(0.5);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      animation: swing 4s ease-in-out infinite;
      transform-origin: center -20px;
    }
  }
  .mainObj1 {
    aspect-ratio: 144/255;
    top: -7%;
    left: 6%;
    transform-origin: left top;
  }
  .mainObj2 {
    aspect-ratio: 182/254;
    top: 14%;
    left: 15%;
    transform-origin: left top;
  }
  .mainObj3 {
    aspect-ratio: 560/320;
    left: -5%;
    bottom: 0;
    transform-origin: left bottom;
  }
  .mainObj4 {
    aspect-ratio: 218/154;
    top: 1%;
    right: 15%;
    transform-origin: right top;
  }
  .mainObj5 {
    aspect-ratio: 1/1;
    top: 30%;
    right: 1%;
    transform-origin: right top;
  }
  .mainObj6 {
    aspect-ratio: 600/400;
    bottom: -3%;
    right: -5%;
    transform-origin: right bottom;
  }

  @keyframes swing {
    0% {
      transform: rotate3d(0, 0, 1, -5deg);
    }
    50% {
      transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, -5deg);
    }
  }
  p {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  b {
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
    // margin-bottom: 48px;
  }
  @media screen and (max-width: 1200px) {
    padding-top: 20px;
    padding-bottom: 20px;
    .mainObj {
      display: none;
    }
    .imgWrap {
      // margin-top: 20px;
      // margin-bottom: 20px;
    }
    p,
    b {
      font-size: 20px;
    }
    b {
      margin-bottom: 24px;
    }
  }
}

.linkMap {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 48px;
  .inner {
    overflow: auto;
  }
  .tray {
    // min-width: 970px;
    background-color: #fff;
    border-radius: $s2;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    gap: 12px;
    padding: 11px;
    .tit {
      font-size: 20px;
      font-weight: bold;
    }
    .tab {
      margin: 0;
    }
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 70px;
    .tray {
      padding: 11px 20px;
      align-items: flex-start;
      overflow: hidden;
      .tab {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        padding: 0 20px;
        overflow: auto;
      }
      .tit {
        font-size: 20px;
        font-weight: bold;
        align-self: center;
      }
    }
  }
}

.mainContents {
  min-height: 920px;
  margin-bottom: 80px;
  &.reverse .inner {
    flex-direction: row-reverse;
  }
  @include keyword() {
    .item {
      flex: 0 0 auto;
    }
  }
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
  }
  .item {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .big {
    cursor: pointer;
    border-radius: $s2;
    overflow: hidden;
    // background-color: #222;
    padding: 32px 30px;
    flex: 1 0 0;
    // background: #222 center/cover no-repeat;
    position: relative;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    .imgWrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      img {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        transition: cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
        will-change: transform;
      }
    }
    .tit {
      color: #fff;
      font-size: 48px;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 20px;
    }
    .desc {
      color: #fff;
      font-size: 28px;
      line-height: 38px;
    }
  }

  .small {
    cursor: pointer;
    border-radius: $s2;
    overflow: hidden;
    display: flex;
    flex: 1 0 0;
    background-color: #fff;
    .textWrap {
      padding: 28px 30px;
      flex: 1 0 50%;
      box-sizing: border-box;
    }
    .tit {
      font-size: 32px;
      line-height: 42px;
      font-weight: bold;
      margin-top: 12px;
      margin-bottom: 20px;
      @include lineClamp(2);
    }
    .desc {
      font-size: 24px;
      line-height: 1.5;
      @include lineClamp(3);
    }
    .imgWrap {
      flex: 1 0 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
        will-change: transform;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    margin: 0 auto;
    margin-bottom: 40px;
    .innerFull {
      flex-direction: column !important;
    }
    .big {
      aspect-ratio: 1/1;
      max-height: 340px;
      box-sizing: border-box;
      padding: 12px;
      .tit {
        font-size: 28px;
        margin-bottom: 4px;
      }
      .desc {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .small {
      // flex-direction: column;
      height: 400px;
      display: block;
      .textWrap {
        padding: 16px;
        // min-height: 187px;
      }
      .imgWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-height: 250px;
        aspect-ratio: 332/211;
      }
      .tit {
        font-size: 20px;
        line-height: 1.4;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

.article4 {
  margin-bottom: 80px;
  .inner {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    font-size: 18px;
    line-height: 2;
  }
  .imgWrap {
    img {
      width: 100%;
    }
  }
}

// Article5
.article5 {
  .inner {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  .h3Tit {
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
  }
  .titWrap {
    .tit {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 12px;
    }
    p {
      font-size: 18px;
      line-height: 2;
      a {
        color: inherit;
      }
    }
  }
}

.oldAndNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  table {
    text-align: center;
    table-layout: fixed;
    width: 100%;
    th {
      padding: 5px;
      border: 1px solid #000;
      font-size: 14px;
      font-weight: 600;
    }
    td {
      padding: 17px;
      border: 1px solid #000;
      font-size: 18px;
    }
  }
  .old {
    th {
      background-color: #cbcbcb;
    }
  }
  .new {
    th {
      background-color: #ffdb43;
    }
  }
}

.profileCardWrap {
  display: flex;
  gap: 4px;
  margin-top: -32px;
  .profileCard {
    text-align: center;
    border: 1px solid #000;
    font-size: 16px;
    line-height: 1.5;
    flex: 1 0 0;
    padding-top: 16px;
    box-sizing: border-box;
    &.red {
      background-color: #ff9f9f;
    }
    &.blue {
      background-color: #9bbeff;
    }
    em {
      font-weight: 600;
      margin-bottom: 4px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
    }
    img {
      display: block;
      width: 100%;
      aspect-ratio: 1/1;
    }
    ul {
      padding: 15px;
      text-align: left;
      li {
        position: relative;
        padding-left: 16px;
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000;
          position: absolute;
          top: 9px;
          left: 0;
        }
      }
      li + li {
        margin-top: 16px;
      }
    }
  }
}

.pushContents {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  &.top {
    padding-top: 36px;
    border-top: 1px solid #000;
    margin-bottom: 48px;
  }
}
.yellowLink {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #000;
  border-radius: 50px;
  background-color: #ffdb43;
  width: 223px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #000;
  margin: 0 auto;
}

.interviewFooter {
  width: 100%;
  text-align: right;
  .conBx {
    border-top: 1px solid #000;
    padding-top: 24px;
  }
}

.greenBaseWrap {
  .conTit {
    font-size: 24px;
  }
  p {
    font-size: 18px;
    line-height: 28px;
  }
}
.greenBase {
  color: #fff;
  padding: 20px;
  padding-bottom: 36px;
  background-color: #0b965b;
  p {
    font-size: 20px;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .infoImg {
    display: flex;
    flex-direction: column;
    gap: 20px;
    img {
      border-radius: 16px;
    }
  }
  .linkWrap {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 28px;
  }
  .whiteLink {
    display: inline-block;
    background-color: #fff;
    padding: 8px 12px;
    color: #0a965b;
    font-size: 16px;
    border-radius: 100px;
    margin: 0 auto;
    text-decoration: none;
  }
}
