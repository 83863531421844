@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

Select {
  font-family: inherit; /* 폰트 상속 */
  /* 네이티브 화살표를 커스텀 화살표로 대체 */
  -webkit-appearance: none;
  /* 네이티브 외형 감추기 */
  -moz-appearance: none;
  appearance: none;
}

Select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear {
  display: none;
}

/* input type number 에서 화살표 제거 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


html {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, HelveticaNeue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  color: #000;
}

body {
  min-width: 360px;
}

.hashLink {
  display: block;
  height: 70px;
  margin-top: -70px;
  visibility: hidden;
}

.slick-slide img {
  width: 100%;
}

.conTit {
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
}

.desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
}
.desc a {
  color: inherit;
}
.desc b {
  font-weight: bold;
}
.desc b.red {
  color: #ff7d72;
}
.desc b.blue {
  color: #558eff;
}

.flexColumn4 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.flexColumn8 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flexColumn20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexColumn36 {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.flexColumn80 {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.flexColumn80 .innerFlex {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.displayNone {
  display: none !important;
}

.gap12 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}

.blueLink {
  min-width: 223px;
  text-align: center;
  padding: 8px 12px;
  border-radius: 100px;
  border: 1px solid #000;
  box-sizing: border-box;
  background-color: #81c0ff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.imgWrap {
  text-align: right;
}
.imgWrap img {
  display: block;
  width: 100%;
}
.imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}

.detailInfo {
  padding-top: 12px;
  border-top: 1px solid #707070;
}
.detailInfo table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}
.detailInfo th,
.detailInfo td {
  padding-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.detailInfo th {
  font-family: "Pretendard";
  width: 60px;
  font-weight: bold;
}
.detailInfo td {
  padding-left: 12px;
}
.detailInfo a {
  font-family: "Pretendard";
  color: inherit;
}

.inner {
  box-sizing: border-box;
  width: 100%;
  max-width: 616px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.inner.innerFlex {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 80px;
  margin-bottom: 110px;
}

.innerFull {
  max-width: 100%;
}

.inner380 .inner {
  box-sizing: border-box;
  width: 100%;
  max-width: 616px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 410px;
}
.inner380 .inner.innerFlex {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 80px;
  margin-bottom: 110px;
}
.inner380 .article1 h2 {
  font-size: 30px;
}
.inner380 .article1 .subTit {
  margin-bottom: 0;
}
.inner380 .article1 .keyImg {
  margin: 0;
}
.inner380 .article1 .keyImg img {
  display: block;
}
.inner380 .fullImg {
  display: block;
  width: 100%;
  margin: 0;
}

.inner1220 {
  max-width: 1220px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 24px;
  font-weight: bold;
  background-color: #b1d6db;
  background-color: #ffcf4b;
  border-bottom: 1px solid #000;
}
.header .hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url(../public/img/hamburger.svg) center no-repeat;
}
.header .inner {
  max-width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
  transition: 0.3s;
}
.header .left {
  display: flex;
  align-items: center;
  gap: 56px;
  transition: 0.3s;
}
.header .tit {
  font-family: "Montserrat";
  font-weight: 800;
}
@media screen and (max-width: 768px) {
  .header .inner {
    padding: 0 20px;
  }
  .header .left {
    gap: 30px;
  }
}

.offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
}
.offcanvas.ac {
  display: block;
}
.offcanvas .sideMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 364px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #707070;
}
.offcanvas .sideMenu .close {
  text-indent: -9999px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  padding: 10px;
  background: url(../public/img/ic_sideClose.svg) center no-repeat;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.offcanvas .sideMenu .tit {
  font-family: "Montserrat";
  padding-top: 120px;
  padding-left: 30px;
  font-size: 36px;
  font-weight: 900;
  padding-bottom: 24px;
}
.offcanvas .sideMenu .menuList {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.offcanvas .sideMenu .menuList .menu {
  transition: 0.3s;
  cursor: pointer;
  padding: 28px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  box-sizing: border-box;
  border: 1px solid #707070;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.offcanvas .sideMenu .menuList .menu:last-child {
  border-bottom: 1px solid #707070;
}
.offcanvas .sideMenu .menuList .menu.ac, .offcanvas .sideMenu .menuList .menu:hover {
  background-color: #ffdf74;
}
.offcanvas .sideMenu .menuList .menu h4 {
  font-size: 20px;
  font-weight: bold;
}
.offcanvas .sideMenu .menuList .menu p {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .dim {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .dim.ac {
    display: block;
  }
}
.d-pc {
  display: block !important;
}

.d-mobile {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .d-pc {
    display: none !important;
  }
  .d-mobile {
    display: block !important;
  }
}
.search {
  position: relative;
}
.search input {
  font-size: 14px;
  width: 283px;
  height: 36px;
  border: 1px solid #707070;
  padding: 0 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 100px;
  outline: 0;
}
.search input ::-moz-placeholder {
  color: #969696;
}
.search input ::placeholder {
  color: #969696;
}
.search .btn {
  padding: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_search.svg) center no-repeat;
  border: 0;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  text-indent: -9999px;
  overflow: hidden;
  transition: 0.3s;
}
.search.d-mobile .btn {
  right: 0;
}
@media screen and (max-width: 768px) {
  .search.ac {
    display: block !important;
    position: fixed;
    top: 96px;
    left: 16px;
    right: 16px;
  }
  .search.ac input {
    width: 100%;
    height: 56px;
  }
  .search.ac .btn {
    right: 20px;
  }
}

.article1 {
  transition: 0.3s;
  margin-top: 63px;
  margin-bottom: 80px;
}
.article1 .keyword {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;
}
.article1 .keyword .item {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
}
.article1 .keyword .item.NEWS {
  background-color: #ffdb43;
}
.article1 .keyword .item.BRAND {
  background-color: #29ddb9;
}
.article1 .keyword .item.TRAVEL {
  background-color: #9099ff;
}
.article1 .keyword .item.ACTIVITY {
  background-color: #ff7efc;
}
.article1 .keyword .item.HISTORY {
  background-color: #6abaff;
}
.article1 .keyword .item.HOW-TO {
  background-color: #b4fa6b;
}
.article1 .keyword .item.PEOPLE {
  background-color: #ff8096;
}
.article1 .keyword .item.SPACE {
  background-color: #a7e8f4;
}
.article1 h2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 8px;
}
.article1 .subTit {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 48px;
}
.article1 .keyImg {
  margin: 48px 0;
}
.article1 .keyImg img {
  width: 100%;
}
.article1 .infoBx {
  padding: 20px;
  border: 1px solid #000;
  background-color: #f2f2f2;
  box-sizing: border-box;
}
.article1 .infoBx .tit {
  font-size: 18px;
  font-weight: bold;
}
.article1 .infoBx .list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
.article1 .infoBx .list .item {
  position: relative;
  padding-left: 16px;
  line-height: 1.5;
}
.article1 .infoBx .list .item::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background-color: #000;
}
.article1 .infoBx .list .item h3 {
  font-size: 16px;
  font-weight: 600;
}
.article1 .infoBx .list .item p {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .article1 {
    margin-top: 36px;
    margin-bottom: 45px;
  }
  .article1 h2 {
    margin-bottom: 4px;
  }
}

.article1 + .article2 {
  margin-top: 80px;
}

.article2 {
  margin-top: 110px;
}
.article2 .titWrap {
  padding-bottom: 28px;
  margin-bottom: 28px;
}
.article2.orange .titWrap {
  border-bottom: 1px solid #faad33;
}
.article2.blue .titWrap {
  border-bottom: 1px solid #81c0ff;
}
.article2.green .titWrap {
  border-bottom: 1px solid #5ae093;
}
.article2 h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}
.article2 p {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
}
.article2.orange h3 {
  color: #ff7600;
}
.article2.blue h3 {
  color: #3198ff;
}
.article2.green h3 {
  color: #0dbc58;
}

.contents + .contents {
  margin-top: 80px;
}
.contents .imgWrap {
  text-align: right;
}
.contents .imgWrap img {
  display: block;
  width: 100%;
}
.contents .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.contents .imgWrap + .imgWrap {
  margin-top: 16px;
}
.contents .itemWrap {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 8px;
}
.contents .itemWrap .tag {
  display: none;
  font-size: 14px;
  color: #707070;
  align-items: center;
  height: 28px;
  border-radius: 40px;
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 0 12px;
}
.contents .itemWrap .tag.dog, .contents .itemWrap .tag.kiz, .contents .itemWrap .tag.obs {
  display: inline-flex;
}
.contents .itemWrap .tag.dog::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_dog.svg) center no-repeat;
}
.contents .itemWrap .tag.kiz::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_kiz.svg) center no-repeat;
}
.contents .itemWrap .tag.obs::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_obs.svg) center no-repeat;
}
.contents .conTit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
.contents .location {
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  margin-bottom: 28px;
}
.contents .location::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_location.svg) center no-repeat;
}
.contents .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 28px;
}
.contents .checkList {
  padding-top: 24px;
  border-top: 1px solid #707070;
}
.contents .checkList .tit {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 20px;
  position: relative;
}
.contents .checkList .tit::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_checkList.svg) center no-repeat;
  margin-bottom: 16px;
}
.contents .checkList .listWrap {
  padding-left: 20px;
  padding-top: 16px;
}
.contents .checkList .list {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-left: 20px;
  color: #000;
}
.contents .checkList .list a {
  color: inherit;
}
.contents .checkList .list::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_check.svg) center no-repeat;
}
.contents .checkList .list + .list {
  margin-top: 12px;
}
.contents .stayLink {
  text-align: center;
  margin-top: 40px;
}
.contents .stayLink .stayBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
}
.contents .stayLink.orange .stayBtn {
  background-color: #faad33;
}
.contents .stayLink.blue .stayBtn {
  background-color: #81c0ff;
}
.contents .stayLink.green .stayBtn {
  background-color: #5ae093;
}
.contents .detailInfo {
  padding-top: 12px;
  border-top: 1px solid #707070;
  margin-top: 28px;
}
.contents .detailInfo table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}
.contents .detailInfo th,
.contents .detailInfo td {
  padding-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.contents .detailInfo th {
  font-family: "Pretendard";
  width: 60px;
  font-weight: bold;
}
.contents .detailInfo td {
  padding-left: 12px;
}
.contents .detailInfo a {
  font-family: "Pretendard";
  color: inherit;
}
.contents.contents2 .cate {
  font-size: 20px;
  font-weight: bold;
  font-family: "Pretendard";
  margin-bottom: 8px;
}
.contents.contents2 .cate.YOGA {
  color: #6abaff;
}
.contents.contents2 .cate.FOOD {
  color: #ff9055;
}
.contents.contents2 .cate.TEA {
  color: #29ddb9;
}
.contents.contents2 .cate.DRAWING {
  color: #ff8096;
}
.contents.contents2 .cate.CRAFT {
  color: #9099ff;
}
.contents.contents2 .conTit {
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 8px;
}
.contents.contents2 .itemWrap {
  margin: 0;
  margin-bottom: 28px;
}
.contents.contents2 .desc {
  margin-top: 28px;
}
.contents + .contents {
  margin-top: 80px;
}
.contents .imgWrap {
  text-align: right;
}
.contents .imgWrap img {
  display: block;
  width: 100%;
}
.contents .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.contents .imgWrap + .imgWrap {
  margin-top: 16px;
}
.contents .itemWrap {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 8px;
}
.contents .itemWrap .tag {
  display: none;
  font-size: 14px;
  color: #707070;
  align-items: center;
  height: 28px;
  border-radius: 40px;
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 0 12px;
}
.contents .itemWrap .tag.dog, .contents .itemWrap .tag.kiz, .contents .itemWrap .tag.obs {
  display: inline-flex;
}
.contents .itemWrap .tag.dog::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_dog.svg) center no-repeat;
}
.contents .itemWrap .tag.kiz::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_kiz.svg) center no-repeat;
}
.contents .itemWrap .tag.obs::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_obs.svg) center no-repeat;
}
.contents .conTit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
.contents .location {
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  margin-bottom: 28px;
}
.contents .location::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_location.svg) center no-repeat;
}
.contents .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 28px;
}
.contents .checkList {
  padding-top: 24px;
  border-top: 1px solid #707070;
}
.contents .checkList .tit {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 20px;
  position: relative;
}
.contents .checkList .tit::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_checkList.svg) center no-repeat;
  margin-bottom: 16px;
}
.contents .checkList .listWrap {
  padding-left: 20px;
  padding-top: 16px;
}
.contents .checkList .list {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-left: 20px;
  color: #000;
}
.contents .checkList .list a {
  color: inherit;
}
.contents .checkList .list::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_check.svg) center no-repeat;
}
.contents .checkList .list + .list {
  margin-top: 12px;
}
.contents .stayLink {
  text-align: center;
  margin-top: 40px;
}
.contents .stayLink .stayBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
}
.contents .stayLink.orange .stayBtn {
  background-color: #faad33;
}
.contents .stayLink.blue .stayBtn {
  background-color: #81c0ff;
}
.contents .stayLink.green .stayBtn {
  background-color: #5ae093;
}
.contents .detailInfo {
  padding-top: 12px;
  border-top: 1px solid #707070;
  margin-top: 28px;
}
.contents .detailInfo table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}
.contents .detailInfo th,
.contents .detailInfo td {
  padding-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.contents .detailInfo th {
  font-family: "Pretendard";
  width: 60px;
  font-weight: bold;
}
.contents .detailInfo td {
  padding-left: 12px;
}
.contents .detailInfo a {
  font-family: "Pretendard";
  color: inherit;
}
.contents.contents3 {
  margin-bottom: 80px;
}
.contents.contents3 em {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #6abaff;
  margin-bottom: 8px;
}
.contents.contents3 .imgWrap {
  margin-bottom: 28px;
}
.contents.contents3 .conTit {
  font-size: 24px;
}

.contents + .contents {
  margin-top: 80px;
}
.contents .imgWrap {
  text-align: right;
}
.contents .imgWrap img {
  display: block;
  width: 100%;
}
.contents .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.contents .imgWrap + .imgWrap {
  margin-top: 16px;
}
.contents .itemWrap {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 8px;
}
.contents .itemWrap .tag {
  display: none;
  font-size: 14px;
  color: #707070;
  align-items: center;
  height: 28px;
  border-radius: 40px;
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 0 12px;
}
.contents .itemWrap .tag.dog, .contents .itemWrap .tag.kiz, .contents .itemWrap .tag.obs {
  display: inline-flex;
}
.contents .itemWrap .tag.dog::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_dog.svg) center no-repeat;
}
.contents .itemWrap .tag.kiz::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_kiz.svg) center no-repeat;
}
.contents .itemWrap .tag.obs::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_obs.svg) center no-repeat;
}
.contents .conTit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
.contents .location {
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  margin-bottom: 28px;
}
.contents .location::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_location.svg) center no-repeat;
}
.contents .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 28px;
}
.contents .checkList {
  padding-top: 24px;
  border-top: 1px solid #707070;
}
.contents .checkList .tit {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 20px;
  position: relative;
}
.contents .checkList .tit::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_checkList.svg) center no-repeat;
  margin-bottom: 16px;
}
.contents .checkList .listWrap {
  padding-left: 20px;
  padding-top: 16px;
}
.contents .checkList .list {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-left: 20px;
  color: #000;
}
.contents .checkList .list a {
  color: inherit;
}
.contents .checkList .list::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_check.svg) center no-repeat;
}
.contents .checkList .list + .list {
  margin-top: 12px;
}
.contents .stayLink {
  text-align: center;
  margin-top: 40px;
}
.contents .stayLink .stayBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
}
.contents .stayLink.orange .stayBtn {
  background-color: #faad33;
}
.contents .stayLink.blue .stayBtn {
  background-color: #81c0ff;
}
.contents .stayLink.green .stayBtn {
  background-color: #5ae093;
}
.contents .detailInfo {
  padding-top: 12px;
  border-top: 1px solid #707070;
  margin-top: 28px;
}
.contents .detailInfo table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}
.contents .detailInfo th,
.contents .detailInfo td {
  padding-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.contents .detailInfo th {
  font-family: "Pretendard";
  width: 60px;
  font-weight: bold;
}
.contents .detailInfo td {
  padding-left: 12px;
}
.contents .detailInfo a {
  font-family: "Pretendard";
  color: inherit;
}
.contents.contents4 {
  margin-bottom: 80px;
}
.contents.contents4 .inner .detailInfo,
.contents.contents4 .inner .desc,
.contents.contents4 .inner .reactSlick,
.contents.contents4 .inner .conTit {
  margin: 0;
}

.contents + .contents {
  margin-top: 80px;
}
.contents .imgWrap {
  text-align: right;
}
.contents .imgWrap img {
  display: block;
  width: 100%;
}
.contents .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.contents .imgWrap + .imgWrap {
  margin-top: 16px;
}
.contents .itemWrap {
  display: flex;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 8px;
}
.contents .itemWrap .tag {
  display: none;
  font-size: 14px;
  color: #707070;
  align-items: center;
  height: 28px;
  border-radius: 40px;
  box-sizing: border-box;
  border: 1px solid #707070;
  padding: 0 12px;
}
.contents .itemWrap .tag.dog, .contents .itemWrap .tag.kiz, .contents .itemWrap .tag.obs {
  display: inline-flex;
}
.contents .itemWrap .tag.dog::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_dog.svg) center no-repeat;
}
.contents .itemWrap .tag.kiz::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_kiz.svg) center no-repeat;
}
.contents .itemWrap .tag.obs::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_obs.svg) center no-repeat;
}
.contents .conTit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
.contents .location {
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  margin-bottom: 28px;
}
.contents .location::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_location.svg) center no-repeat;
}
.contents .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 28px;
}
.contents .checkList {
  padding-top: 24px;
  border-top: 1px solid #707070;
}
.contents .checkList .tit {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  padding-left: 20px;
  position: relative;
}
.contents .checkList .tit::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_checkList.svg) center no-repeat;
  margin-bottom: 16px;
}
.contents .checkList .listWrap {
  padding-left: 20px;
  padding-top: 16px;
}
.contents .checkList .list {
  position: relative;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-left: 20px;
  color: #000;
}
.contents .checkList .list a {
  color: inherit;
}
.contents .checkList .list::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../public/img/ic_check.svg) center no-repeat;
}
.contents .checkList .list + .list {
  margin-top: 12px;
}
.contents .stayLink {
  text-align: center;
  margin-top: 40px;
}
.contents .stayLink .stayBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
}
.contents .stayLink.orange .stayBtn {
  background-color: #faad33;
}
.contents .stayLink.blue .stayBtn {
  background-color: #81c0ff;
}
.contents .stayLink.green .stayBtn {
  background-color: #5ae093;
}
.contents .detailInfo {
  padding-top: 12px;
  border-top: 1px solid #707070;
  margin-top: 28px;
}
.contents .detailInfo table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}
.contents .detailInfo th,
.contents .detailInfo td {
  padding-top: 12px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}
.contents .detailInfo th {
  font-family: "Pretendard";
  width: 60px;
  font-weight: bold;
}
.contents .detailInfo td {
  padding-left: 12px;
}
.contents .detailInfo a {
  font-family: "Pretendard";
  color: inherit;
}
.contents.contents5 {
  margin-bottom: 110px;
}
.contents.contents5 .orderOnline {
  text-align: center;
  margin-top: 40px;
}
.contents.contents5 .orderOnline .orderBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
  background-color: #d9c8c2;
}
.contents.contents5 .orderOnline .orderBtn {
  background-color: #ffdb43;
}
.contents.contents5 .inner {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.contents.contents5 em {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #6abaff;
  margin-bottom: 8px;
}
.contents.contents5 b {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  display: inline-block;
}
.contents.contents5 .conTit {
  font-size: 24px;
}
.contents.contents5 .detailInfo {
  padding: 0;
}
.contents.contents5 .detailInfo th {
  width: 70px;
}
.contents.contents5 .detailInfo th,
.contents.contents5 .detailInfo td {
  padding-top: 28px;
}
.contents.contents5 .detailInfo tr:first-child td,
.contents.contents5 .detailInfo tr:first-child th {
  padding-top: 24px;
}
.contents.contents5 .imgWrap {
  text-align: left;
}
.contents.contents5 .imgList {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.contents.contents5 .imgWrap {
  text-align: right;
}
.contents.contents5 .imgWrap img {
  display: block;
  width: 100%;
}
.contents.contents5 .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.contents.contents5 .video iframe {
  width: 100%;
}
.contents.contents5 .imgWrap,
.contents.contents5 .orderOnline,
.contents.contents5 .detailInfo,
.contents.contents5 .desc,
.contents.contents5 .reactSlick,
.contents.contents5 .conTit {
  margin: 0;
}

.recommend {
  margin-top: 80px;
  margin-bottom: 230px;
}
.recommend .tit {
  padding-top: 32px;
  border-top: 1px solid #707070;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 28px;
}
.recommend .cardWrap {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.recommend .card {
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  background-color: #eaeaea;
  box-sizing: border-box;
  min-height: 208px;
}
.recommend .card .imgWrap {
  width: 100%;
  aspect-ratio: 283/182;
  border-radius: 12px;
  overflow: hidden;
}
.recommend .card .imgWrap img {
  display: block;
  aspect-ratio: 285/210;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.recommend .card .rightCon {
  flex: 1 0 0;
  padding-top: 12px;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}
.recommend .card .keyword {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.recommend .card .keyword .item {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
}
.recommend .card .keyword .item.NEWS {
  background-color: #ffdb43;
}
.recommend .card .keyword .item.BRAND {
  background-color: #29ddb9;
}
.recommend .card .keyword .item.TRAVEL {
  background-color: #9099ff;
}
.recommend .card .keyword .item.ACTIVITY {
  background-color: #ff7efc;
}
.recommend .card .keyword .item.HISTORY {
  background-color: #6abaff;
}
.recommend .card .keyword .item.HOW-TO {
  background-color: #b4fa6b;
}
.recommend .card .keyword .item.PEOPLE {
  background-color: #ff8096;
}
.recommend .card .keyword .item.SPACE {
  background-color: #a7e8f4;
}
.recommend .card .keyword .item {
  font-weight: 500;
  line-height: 20px;
}
.recommend .card .conTit {
  font-size: 18px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 4px;
}
.recommend .card .desc {
  font-size: 16px;
  line-height: 1.5;
}

.article3 {
  margin-top: 80px;
}
.article3 .em {
  font-size: 18px;
  font-weight: bold;
  color: #151515;
  margin-bottom: 8px;
}
.article3 .tit {
  font-size: 24px;
  margin-bottom: 28px;
  font-weight: bold;
}
.article3 .imgWrap img {
  width: 100%;
}
.article3 .imgWrap .caption {
  font-size: 12px;
  color: #707070;
  margin-top: 4px;
}
.article3 p {
  margin-top: 28px;
  font-size: 18px;
  line-height: 2;
  margin-bottom: 28px;
}
.article3 .location {
  position: relative;
  padding-left: 20px;
  line-height: 20px;
  margin-bottom: 28px;
}
.article3 .location::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: url(../public/img/ic_location.svg) center no-repeat;
}
.article3 .location {
  line-height: 1.5;
}
.article3 .orderOnline {
  text-align: center;
  margin-top: 40px;
}
.article3 .orderOnline .orderBtn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  padding: 0 12px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
  color: #000;
  background-color: #d9c8c2;
}

.listGroupWrap {
  margin-bottom: 80px;
  margin-top: -80px;
  margin-top: -42px;
  padding-top: 28px;
}
.listGroupWrap .keyImg {
  margin-top: 80px;
  margin-bottom: 28px;
}
.listGroupWrap .keyImg img {
  width: 100%;
}
.listGroupWrap .badgeLink {
  margin: 24px 0;
}
.listGroupWrap .badgeLink a {
  padding: 8px 12px;
  border: 1px solid #000;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #3198ff;
  text-decoration: none;
}

.listGroup {
  font-size: 18px;
  font-family: "Pretendard";
  line-height: 1.5;
}
.listGroup + .listGroup {
  margin-top: 24px;
}
.listGroup .listTit {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}
.listGroup .listTit .num {
  font-family: "Pretendard";
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3198ff;
  border-radius: 20px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}
.listGroup .listTit .num + .num {
  margin-left: -4px;
}
.listGroup .listTit .tit {
  font-weight: bold;
}
.listGroup .listDesc .num {
  font-family: "Pretendard";
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3198ff;
  border-radius: 20px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}
.listGroup .sub {
  font-size: 16px;
  line-height: 1.5;
  color: #707070;
}

.reactSlick {
  padding-bottom: 23px;
  margin-bottom: 80px;
}
.reactSlick img {
  width: 100%;
}
.reactSlick .slick-slide div {
  outline: 0;
}
.reactSlick .slick-dots li {
  margin: 0;
}
.reactSlick .slick-dots li button {
  width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-indent: -9999px;
  overflow: hidden;
}
.reactSlick .slick-dots li button::before {
  position: static;
  opacity: 1;
  padding: 0;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #707070;
  box-sizing: border-box;
}
.reactSlick .slick-dots li.slick-active button::before {
  border: 0;
  background-color: #3198ff;
}

.postImg {
  margin-bottom: 80px;
}
.postImg img {
  width: 100%;
}

.contents3 {
  margin-bottom: 28px;
}
.contents3 h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 28px;
}
.contents3 h3.blue {
  color: #3198ff;
}
.contents3 h4 {
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 12px;
}
.contents3 p {
  font-size: 18px;
  line-height: 2;
}
.contents3 .insta {
  font-family: "Pretendard";
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
  color: #707070;
  padding-left: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  background: url(../public/img/ic_insta.svg) left center/32px no-repeat;
}

.cardView {
  margin-bottom: 60px;
}
.cardView .inner {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  max-width: 1220px;
}
.cardView .card {
  width: calc((100% - 40px) / 3);
  padding: 10px 10px 20px 10px;
  border-radius: 16px;
  background-color: #eaeaea;
  box-sizing: border-box;
  flex: 0 0 auto;
  transition: transform 0.3s ease-out 0s;
}
.cardView .card:hover {
  transform: translateY(-16px);
}
.cardView .card .imgWrap {
  aspect-ratio: 364/273;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
}
.cardView .card .imgWrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.cardView .card .imgWrap .badgeWrap {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
}
.cardView .card .imgWrap .badgeWrap .badge {
  width: 28px;
  height: 28px;
}
.cardView .card .imgWrap .badgeWrap .badge.kiz {
  background: url(../public/img/ic_badge_kiz.png) center no-repeat;
}
.cardView .card .keywordWrap {
  min-height: 52px;
}
.cardView .card .keyword {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cardView .card .keyword .item {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
}
.cardView .card .keyword .item.NEWS {
  background-color: #ffdb43;
}
.cardView .card .keyword .item.BRAND {
  background-color: #29ddb9;
}
.cardView .card .keyword .item.TRAVEL {
  background-color: #9099ff;
}
.cardView .card .keyword .item.ACTIVITY {
  background-color: #ff7efc;
}
.cardView .card .keyword .item.HISTORY {
  background-color: #6abaff;
}
.cardView .card .keyword .item.HOW-TO {
  background-color: #b4fa6b;
}
.cardView .card .keyword .item.PEOPLE {
  background-color: #ff8096;
}
.cardView .card .keyword .item.SPACE {
  background-color: #a7e8f4;
}
.cardView .card h3 {
  font-family: "Pretendard";
  margin-top: 16px;
  min-height: 60px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 4px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.cardView .card p {
  font-family: "Pretendard";
  font-size: 16px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.cardView .card.card2 {
  width: calc((100% - 40px) / 2);
}
.cardView .card.card2 h3 {
  min-height: initial;
}
@media screen and (max-width: 768px) {
  .cardView .inner {
    row-gap: 20px;
  }
  .cardView .inner .card {
    width: 100%;
  }
  .cardView .inner .card:hover {
    transform: none;
  }
}

.tab {
  margin-top: 83px;
  margin-bottom: 20px;
  transition: 0.3s;
}
.tab [class*=inner] {
  overflow: auto;
}
.tab .keyword {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  min-height: 31px;
  flex-wrap: nowrap;
}
.tab .keyword .item {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
}
.tab .keyword .item.NEWS {
  background-color: #ffdb43;
}
.tab .keyword .item.BRAND {
  background-color: #29ddb9;
}
.tab .keyword .item.TRAVEL {
  background-color: #9099ff;
}
.tab .keyword .item.ACTIVITY {
  background-color: #ff7efc;
}
.tab .keyword .item.HISTORY {
  background-color: #6abaff;
}
.tab .keyword .item.HOW-TO {
  background-color: #b4fa6b;
}
.tab .keyword .item.PEOPLE {
  background-color: #ff8096;
}
.tab .keyword .item.SPACE {
  background-color: #a7e8f4;
}
.tab .keyword .item.NEWS,
.tab .keyword .item.BRAND,
.tab .keyword .item.TRAVEL,
.tab .keyword .item.ACTIVITY,
.tab .keyword .item.HISTORY,
.tab .keyword .item.HOW-TO,
.tab .keyword .item.PEOPLE,
.tab .keyword .item.SPACE,
.tab .keyword .item.MAP {
  background-color: transparent;
}
.tab .keyword .item.NEWS:hover,
.tab .keyword .ac.NEWS {
  background-color: #ffdb43;
}
.tab .keyword .item.BRAND:hover,
.tab .keyword .ac.BRAND {
  background-color: #29ddb9;
}
.tab .keyword .item.TRAVEL:hover,
.tab .keyword .ac.TRAVEL {
  background-color: #9099ff;
}
.tab .keyword .item.ACTIVITY:hover,
.tab .keyword .ac.ACTIVITY {
  background-color: #ff7efc;
}
.tab .keyword .item.HISTORY:hover,
.tab .keyword .ac.HISTORY {
  background-color: #6abaff;
}
.tab .keyword .item.HOW-TO:hover,
.tab .keyword .ac.HOW-TO {
  background-color: #b4fa6b;
}
.tab .keyword .item.PEOPLE:hover,
.tab .keyword .ac.PEOPLE {
  background-color: #ff8096;
}
.tab .keyword .item.SPACE:hover,
.tab .keyword .ac.SPACE {
  background-color: #a7e8f4;
}
.tab .keyword .item.MAP:hover,
.tab .keyword .ac.SPACE {
  background-color: #ff9055;
}
.tab .keyword .item {
  white-space: nowrap;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  cursor: pointer;
  box-sizing: border-box;
}
@media screen and (max-width: 768px) {
  .tab {
    margin-top: 36px;
  }
}

.cateTit h2 {
  font-family: "Pretendard";
  font-size: 48px;
  line-height: 58px;
  font-weight: bold;
  margin-bottom: 4px;
  transition: 0.3s;
}
.cateTit h3 {
  font-family: "Pretendard";
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 48px;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  .cateTit h2 {
    font-size: 32px;
    line-height: 38px;
  }
  .cateTit h3 {
    font-size: 20px;
    margin-bottom: 45px;
  }
}

body:has(.mainBg) {
  background: url(../public/img/bg_main.jpg) center;
}

.visual {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.keyVisualWrap {
  position: relative;
  width: 1920px;
  flex: 0 0 auto;
}
@media screen and (max-width: 1200px) {
  .keyVisualWrap {
    flex: 1 0 0;
  }
}

.keyVisual {
  text-align: center;
  padding: 0 15px;
  padding-top: 124px;
  padding-bottom: 48px;
  transition: 0.3s;
}
.keyVisual .imgWrap {
  margin: 0 auto;
  max-width: 665px;
  width: 100%;
  aspect-ratio: 665/300;
}
.keyVisual .imgWrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.keyVisual .mainObj {
  position: absolute;
  transform: scale(0.5);
}
.keyVisual .mainObj img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  animation: swing 4s ease-in-out infinite;
  transform-origin: center -20px;
}
.keyVisual .mainObj1 {
  aspect-ratio: 144/255;
  top: -7%;
  left: 6%;
  transform-origin: left top;
}
.keyVisual .mainObj2 {
  aspect-ratio: 182/254;
  top: 14%;
  left: 15%;
  transform-origin: left top;
}
.keyVisual .mainObj3 {
  aspect-ratio: 560/320;
  left: -5%;
  bottom: 0;
  transform-origin: left bottom;
}
.keyVisual .mainObj4 {
  aspect-ratio: 218/154;
  top: 1%;
  right: 15%;
  transform-origin: right top;
}
.keyVisual .mainObj5 {
  aspect-ratio: 1/1;
  top: 30%;
  right: 1%;
  transform-origin: right top;
}
.keyVisual .mainObj6 {
  aspect-ratio: 600/400;
  bottom: -3%;
  right: -5%;
  transform-origin: right bottom;
}
@keyframes swing {
  0% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
}
.keyVisual p {
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 24px;
}
.keyVisual b {
  display: inline-block;
  font-size: 28px;
  font-weight: bold;
}
@media screen and (max-width: 1200px) {
  .keyVisual {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .keyVisual .mainObj {
    display: none;
  }
  .keyVisual p,
  .keyVisual b {
    font-size: 20px;
  }
  .keyVisual b {
    margin-bottom: 24px;
  }
}

.linkMap {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 48px;
}
.linkMap .inner {
  overflow: auto;
}
.linkMap .tray {
  background-color: #fff;
  border-radius: 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 12px;
  padding: 11px;
}
.linkMap .tray .tit {
  font-size: 20px;
  font-weight: bold;
}
.linkMap .tray .tab {
  margin: 0;
}
@media screen and (max-width: 1200px) {
  .linkMap {
    margin-bottom: 70px;
  }
  .linkMap .tray {
    padding: 11px 20px;
    align-items: flex-start;
    overflow: hidden;
  }
  .linkMap .tray .tab {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    padding: 0 20px;
    overflow: auto;
  }
  .linkMap .tray .tit {
    font-size: 20px;
    font-weight: bold;
    align-self: center;
  }
}

.mainContents {
  min-height: 920px;
  margin-bottom: 80px;
}
.mainContents.reverse .inner {
  flex-direction: row-reverse;
}
.mainContents .keyword {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.mainContents .keyword .item {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 0 8px;
  border-radius: 50px;
  border: 1px solid #000;
  box-sizing: border-box;
}
.mainContents .keyword .item.NEWS {
  background-color: #ffdb43;
}
.mainContents .keyword .item.BRAND {
  background-color: #29ddb9;
}
.mainContents .keyword .item.TRAVEL {
  background-color: #9099ff;
}
.mainContents .keyword .item.ACTIVITY {
  background-color: #ff7efc;
}
.mainContents .keyword .item.HISTORY {
  background-color: #6abaff;
}
.mainContents .keyword .item.HOW-TO {
  background-color: #b4fa6b;
}
.mainContents .keyword .item.PEOPLE {
  background-color: #ff8096;
}
.mainContents .keyword .item.SPACE {
  background-color: #a7e8f4;
}
.mainContents .keyword .item {
  flex: 0 0 auto;
}
.mainContents .inner {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
}
.mainContents .item {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mainContents .big {
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  padding: 32px 30px;
  flex: 1 0 0;
  position: relative;
}
.mainContents .big:hover img {
  transform: scale(1.1);
}
.mainContents .big .imgWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.mainContents .big .imgWrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  transition: cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
  will-change: transform;
}
.mainContents .big .tit {
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 20px;
}
.mainContents .big .desc {
  color: #fff;
  font-size: 28px;
  line-height: 38px;
}
.mainContents .small {
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  background-color: #fff;
}
.mainContents .small .textWrap {
  padding: 28px 30px;
  flex: 1 0 50%;
  box-sizing: border-box;
}
.mainContents .small .tit {
  font-size: 32px;
  line-height: 42px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.mainContents .small .desc {
  font-size: 24px;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.mainContents .small .imgWrap {
  flex: 1 0 50%;
  overflow: hidden;
}
.mainContents .small .imgWrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
  will-change: transform;
}
.mainContents .small .imgWrap img:hover {
  transform: scale(1.1);
}
@media screen and (max-width: 1200px) {
  .mainContents {
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .mainContents .innerFull {
    flex-direction: column !important;
  }
  .mainContents .big {
    aspect-ratio: 1/1;
    max-height: 340px;
    box-sizing: border-box;
    padding: 12px;
  }
  .mainContents .big .tit {
    font-size: 28px;
    margin-bottom: 4px;
  }
  .mainContents .big .desc {
    font-size: 18px;
    line-height: 28px;
  }
  .mainContents .small {
    height: 400px;
    display: block;
  }
  .mainContents .small .textWrap {
    padding: 16px;
  }
  .mainContents .small .imgWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 250px;
    aspect-ratio: 332/211;
  }
  .mainContents .small .tit {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 20px;
  }
  .mainContents .small .desc {
    font-size: 18px;
    line-height: 28px;
  }
}

.article4 {
  margin-bottom: 80px;
}
.article4 .inner {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.article4 h3 {
  font-size: 24px;
  font-weight: bold;
}
.article4 p {
  font-size: 18px;
  line-height: 2;
}
.article4 .imgWrap img {
  width: 100%;
}

.article5 .inner {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.article5 .h3Tit {
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}
.article5 .titWrap .tit {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 12px;
}
.article5 .titWrap p {
  font-size: 18px;
  line-height: 2;
}
.article5 .titWrap p a {
  color: inherit;
}

.oldAndNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.oldAndNew table {
  text-align: center;
  table-layout: fixed;
  width: 100%;
}
.oldAndNew table th {
  padding: 5px;
  border: 1px solid #000;
  font-size: 14px;
  font-weight: 600;
}
.oldAndNew table td {
  padding: 17px;
  border: 1px solid #000;
  font-size: 18px;
}
.oldAndNew .old th {
  background-color: #cbcbcb;
}
.oldAndNew .new th {
  background-color: #ffdb43;
}

.profileCardWrap {
  display: flex;
  gap: 4px;
  margin-top: -32px;
}
.profileCardWrap .profileCard {
  text-align: center;
  border: 1px solid #000;
  font-size: 16px;
  line-height: 1.5;
  flex: 1 0 0;
  padding-top: 16px;
  box-sizing: border-box;
}
.profileCardWrap .profileCard.red {
  background-color: #ff9f9f;
}
.profileCardWrap .profileCard.blue {
  background-color: #9bbeff;
}
.profileCardWrap .profileCard em {
  font-weight: 600;
  margin-bottom: 4px;
}
.profileCardWrap .profileCard h4 {
  font-size: 20px;
  font-weight: 700;
}
.profileCardWrap .profileCard img {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
}
.profileCardWrap .profileCard ul {
  padding: 15px;
  text-align: left;
}
.profileCardWrap .profileCard ul li {
  position: relative;
  padding-left: 16px;
}
.profileCardWrap .profileCard ul li::before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 9px;
  left: 0;
}
.profileCardWrap .profileCard ul li + li {
  margin-top: 16px;
}

.pushContents {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
}
.pushContents.top {
  padding-top: 36px;
  border-top: 1px solid #000;
  margin-bottom: 48px;
}

.yellowLink {
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid #000;
  border-radius: 50px;
  background-color: #ffdb43;
  width: 223px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #000;
  margin: 0 auto;
}

.interviewFooter {
  width: 100%;
  text-align: right;
}
.interviewFooter .conBx {
  border-top: 1px solid #000;
  padding-top: 24px;
}

.greenBaseWrap .conTit {
  font-size: 24px;
}
.greenBaseWrap p {
  font-size: 18px;
  line-height: 28px;
}

.greenBase {
  color: #fff;
  padding: 20px;
  padding-bottom: 36px;
  background-color: #0b965b;
}
.greenBase p {
  font-size: 20px;
}
.greenBase h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}
.greenBase .infoImg {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.greenBase .infoImg img {
  border-radius: 16px;
}
.greenBase .linkWrap {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}
.greenBase .whiteLink {
  display: inline-block;
  background-color: #fff;
  padding: 8px 12px;
  color: #0a965b;
  font-size: 16px;
  border-radius: 100px;
  margin: 0 auto;
  text-decoration: none;
}/*# sourceMappingURL=index.css.map */